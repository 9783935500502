import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Contact from '../components/Contact'

class HomeIndex extends React.Component {
    render() {

        return (
            <Layout>
                <Helmet
                    title="JonWayne Mixing"
                    meta={[
                        { name: 'description', content: 'Contact JonWayne about a mix' },
                        { name: 'keywords', content: 'jonwayne, jon, wayne, mixing, mix, jonwaynemix' },
                    ]}
                >
                </Helmet>
                <Contact />
            </Layout>
        )
    }
}

export default HomeIndex